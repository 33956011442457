
                                function LoadMap(lat, lng) {	
                                  if (GBrowserIsCompatible()) {
                                    map = new GMap2(document.getElementById("mapa"));
                                    map.setCenter(new GLatLng(lng, lat), 13);
                                    var mapControl = new GMapTypeControl();
                                    map.addControl(mapControl);
                                    map.addControl(new GLargeMapControl());	
                                    var point = new GLatLng(lng, lat);
                                    var mk = new GMarker(point);
                                    mk.id = 1;
                                        map.addOverlay(mk);
                                  }
                                }



	  var keyStr = "ABCDEFGHIJKLMNOP" +
	               "QRSTUVWXYZabcdef" +
	               "ghijklmnopqrstuv" +
	               "wxyz0123456789+/" +
	               "=";
	 
function decode64(input) {
	     var output = "";
	     var chr1, chr2, chr3 = "";
	     var enc1, enc2, enc3, enc4 = "";
	     var i = 0;
	 
	     // remove all characters that are not A-Z, a-z, 0-9, +, /, or =
	     var base64test = /[^A-Za-z0-9\+\/\=]/g;
	     if (base64test.exec(input)) {
	        alert("There were invalid base64 characters in the input text.\n" +
	              "Valid base64 characters are A-Z, a-z, 0-9, '+', '/',and '='\n" +
	              "Expect errors in decoding.");
	     }
	     input = input.replace(/[^A-Za-z0-9\+\/\=]/g, "");
	 
	     do {
	        enc1 = keyStr.indexOf(input.charAt(i++));
	        enc2 = keyStr.indexOf(input.charAt(i++));
	        enc3 = keyStr.indexOf(input.charAt(i++));
	        enc4 = keyStr.indexOf(input.charAt(i++));
	 
	        chr1 = (enc1 << 2) | (enc2 >> 4);
	        chr2 = ((enc2 & 15) << 4) | (enc3 >> 2);
	        chr3 = ((enc3 & 3) << 6) | enc4;
	 
	        output = output + String.fromCharCode(chr1);
	 
	        if (enc3 != 64) {
	           output = output + String.fromCharCode(chr2);
	        }
	        if (enc4 != 64) {
	           output = output + String.fromCharCode(chr3);
	        }
	 
	        chr1 = chr2 = chr3 = "";
	        enc1 = enc2 = enc3 = enc4 = "";
	 
	     } while (i < input.length);
	 
	     return unescape(output);
	  }



$.fn.encmail = function(options)
{
    
    for ( var i = 0; i < this.length; i++ )
        {
                $(this[i]).css('display','inline');
                
                var enc = decode64($(this[i]).text());
                $(this[i]).text(enc);
                $(this[i]).attr('href','mailto:'+enc);
        }
    
}


$(function(){
    $('.aemail').encmail();


var isMobile = window.matchMedia("only screen and (max-width: 960px)");
            

       $("tel").click(function(){
        if (isMobile.matches)
          window.location.href="tel:"+$(this).attr("data-phone");
        });
        

        //   $(".specnav li").prepend("<span class=\"mimg\"></span>")
        $(window).bind("load",function(){
               $(".loading").hide();
               if($(".container").width()>1200){
                    $(".logo").width($(".container").width()-$("#navbar .nav").width()+30);
                   }else $(".logo").width("80%");
           });
      /*    $(".dropdown-menu li").click(
            function(){
               $(this).find("ul").show();
            }
          );*/    
    
});